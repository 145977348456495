export default {
  view: 'View profile',
  edit: 'Edit profile',
  edit_description: 'Basic profile data',
  profile: 'Profile',
  info: 'Information',
  security: 'Security',
  call: 'Video Call',
  request_meeting: 'Request meeting',
  open_chat: 'Open Chat',
  send_message: 'Send message',
  name: 'Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  looking: 'I\'m looking for',
  offer: 'I can offer',
  bio: 'About',
  job: 'Job',
  phone: 'Phone',
  upgrade_ticket: 'Upgrade ticket',
  disable_notifications: 'Disable email notifications',
  information: 'Basic information',
  information_description: 'Will be visible to other members.',
  portfolio: 'Portfolio',
  company: 'Company profile',
  company_description: 'Your company information',
  add_game: 'Add Game',
  edit_game: 'Edit Game',
  dev_start_at: 'Development Start Date',
  release_at: 'Release date',
  delete_title: 'Delete game',
  delete_text_1: 'Are you sure you want to delete the game',
  delete_text_2: 'To delete the game, please write to gamefinder@wn.media, enter the name of the game and the company.',
  language: 'Language',
  organizer: 'Organizer',
  sponsor: 'Sponsor',
  speaker: 'Speaker',
  registration_person: 'About yourself',
  registration_company: 'Company',
  no_company: 'No company',
  new_company: '(+ New Company)',
  fill_data_prompt: 'Fill in your profile data',
  registration_text: 'Please fill out the rest of the fields to get access to the Event',
  privacy_policy: 'I accept the {link}',
  privacy_policy_link: 'Privacy Policy',
  games: 'Games',
  linkedin_profile: 'LinkedIn profile',
  whatsapp: 'Contacts',
  whatsapp_placeholder: 'Facebook / WhatsApp / Telegram / WeChat',
  pro: 'PRO'
}
