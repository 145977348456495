export default {
  menu: 'Меню',
  required: 'Поле необходимо заполнить',
  settings: 'Настройки',
  about: 'О приложении',
  logout: 'Выйти',
  volunteer: 'Волонтёр',
  email: 'Почта',
  name: 'Название',
  submit: 'Отправить',
  get_a_ticket: 'Получить билет',
  password: 'Пароль',
  repeat_password: 'Повторите пароль',
  new_password: 'Новый пароль',
  change_password: 'Сменить пароль',
  change_password_description: 'Для смены пароля введите текущий пароль и придумайте новый',
  save: 'Сохранить',
  help: 'Помощь',
  yes: 'Да',
  no: 'Нет',
  contacts: 'Контакты',
  contacts_email: 'Почта',
  contacts_site: 'Сайт',
  contacts_phone: 'Телефон',
  help_text: 'Если у вас есть вопросы, напишите нам, и мы постараемся ответить максимально быстро! Если вопрос безотлагательный и не терпит ни минуты, позвоните по телефону.',
  search: 'Поиск',
  search_placeholder: 'Поиск...',
  tags: 'Теги',
  company: 'Компания',
  companies: 'Компании',
  offer: 'Предлагают',
  look_for: 'Ищут',
  all_variants: 'Все варианты',
  regions: 'Регионы',
  all_regions: 'Все регионы',
  country: 'Страна',
  all_countries: 'Все страны',
  city: 'Город',
  all_cities: 'Все города',
  platform: 'Платформа',
  stage: 'Стадия',
  monetization: 'Монетизация',
  description: 'Описание',
  icon: 'Иконка',
  cover: 'Обложка',
  photo: 'Изображение',
  photos: 'Изображения',
  genre: 'Жанр',
  engine: 'Движок',
  organization: 'Организация',
  call: 'Позвонить',
  not_select: 'Не выбрано',
  select_prompt: 'Выберите значение',
  or: 'или',
  got_it: 'Хорошо',
  delete: 'Удалить',
  cancel: 'Отмена',
  filters: 'Фильтры',
  show: 'Показать',
  error: 'Ошибка',
  file_is_large: 'Файл, который вы пытаетесь загрузить, превышает допустимый размер',
  file_is_not_supported: 'Файл, который вы пытаетесь загрузить, имеет недопустимый тип',
  file_upload_error: 'Ошибка при загрузке файла',
  previous: 'Предыдущая',
  next: 'Следующая',
  link: 'Ссылка',
  links: 'Ссылки',
  add_link: 'Добавить ссылку',
  copy_link: 'Скопировать ссылку',
  joining_info: 'Информация о присоединении',
  sort_by: 'Сортировка',
  no_sort: 'Без сортировки',
  name_asc: 'По названию (А→Я)',
  name_desc: 'По названию (Я→А)',
  date_asc: 'По дате регистрации (старые выше)',
  date_desc: 'По дате регистрации (новые выше)',
  registration_date: 'Дата регистрации',
  last_seen: 'Последний вход',
  over: 'Более',
  sure_to_leave: 'Вы уверены, что хотите уйти с этой страницы?',
  table_empty: 'Нет данных',
  table_empty_description: 'В данном списке нет данных для отображения.',
  timezone: 'Часовой пояс',
  local_time: 'Местное время',
  back: 'Назад',
  business_member: 'Бизнес аккаунт',
  no_matches_message: 'Совпадений не найдено',
  clear_filters: 'Очистить фильтры',
  features: 'Тип участника',
  features_offline: 'Только участники офлайн конференции',
  show_previous: 'Участники предыдущих мероприятий',
  vote: 'Отдать голос',
  vote_note: 'Проголосовать возможно только за одну работу в каждой категории ',
  vote_winner: 'Победитель',
  vote_innovation: 'Инновация',
  vote_relevance: 'Актуальность',
  vote_creativity: 'Креативность',
  source: 'Источник',
  date_added: 'Дата добавления',
  width: 'Ширина',
  height: 'Высота',
  day: { one: 'день', few: 'дня', many: 'дней' },
  hour: { one: 'час', few: 'часа', many: 'часов' },
  minute: { one: 'минута', few: 'минуты', many: 'минут' },
  second: { one: 'секунда', few: 'секунды', many: 'секунд' },
  registered_at: 'Дата регистрации (часовой пояс курса)',
  ticket: 'Билет',
  users: 'Пользователи',
  lectures: 'Лекции',
  online: 'Онлайн',
  onsite: 'On-site',
  event_program: 'Программа мероприятия',
  cookie_privacy: 'Политики Cookie',
  ok: 'Ok'

}
