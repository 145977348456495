export default {
  view: 'Открыть профиль',
  profile: 'Профиль',
  info: 'Информация',
  security: 'Безопасность',
  edit: 'Редактировать профиль',
  edit_description: 'Основные данные вашего профиля',
  call: 'Видео звонок',
  request_meeting: 'Назначить встречу',
  open_chat: 'Открыть чат',
  send_message: 'Отправить сообщение',
  name: 'Имя',
  first_name: 'Имя',
  last_name: 'Фамилия',
  looking: 'Я ищу',
  offer: 'Я могу предложить',
  bio: 'О себе',
  job: 'Должность',
  phone: 'Телефон',
  upgrade_ticket: 'Upgrade ticket',
  disable_notifications: 'Отключить уведомления по почте',
  information: 'Основная информация',
  information_description: 'Информация для отображения другим пользователям',
  portfolio: 'Портфолио',
  company: 'Профиль компании',
  company_description: 'Информация о вашей компании',
  add_game: 'Добавить игру',
  edit_game: 'Редактировать игру',
  dev_start_at: 'Дата начала разработки',
  release_at: 'Дата релиза',
  delete_title: 'Удаление игры',
  delete_text_1: 'Вы уверены, что хотите удалить игру',
  delete_text_2: 'Чтобы удалить игру, напишите на почту gamefinder@wn.media, укажите название игры и компанию.',
  language: 'Выбрать язык',
  organizer: 'Организатор',
  sponsor: 'Спонсор',
  speaker: 'Спикер',
  registration_person: 'О себе',
  registration_company: 'Компания',
  no_company: 'Нет компании',
  new_company: '(+ Добавить компанию)',
  fill_data_prompt: 'Заполните данные профиля',
  registration_text: 'Пожалуйста, заполните остальные поля, чтобы получить доступ к мероприятию',
  privacy_policy: 'Я принимаю {link}',
  privacy_policy_link: 'политику использования персональных данных',
  games: 'Игры',
  linkedin_profile: 'Профиль LinkedIn',
  whatsapp: 'Контакты',
  whatsapp_placeholder: 'Facebook / WhatsApp / Telegram / WeChat',
  pro: 'PRO'
}
