<template>
  <div :id="id"
       :style="{ color: formData.textColor, 'scroll-margin-top': 'env(safe-area-inset-top)', }">
    <div v-if="formData.title"
         class="font-bold text-xl">
      {{ formData.title }}
    </div>
    <div v-if="formData.description"
         class="marked mb-2"
         v-html="marked(formData.description)" />
    <div v-if="!lecture.videoSrc && !lecture.isWNMeetVideo"
         class="text-red-500">
      <p class="mb-4">
        Please check that the URL or source of the video is entered correctly.
      </p>
      <p>Your url: "{{ props.formData.videoUrl }}"</p>
      <p>Your source: "{{ props.formData.source }}"</p>
    </div>
    <BaseRatio v-else
               :ratio="9/16"
               class="bg-black rounded-lg overflow-hidden">
      <iframe v-if="props.formData.source !== VideoSource.WNMeet"
              class="w-full h-full rounded-lg"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              credentialless
              anonymous
              :src="lecture.videoSrc" />
      <template v-else>
        <div v-if="!lecture.tempVideoSrc"
             v-observe-visibility="(visible: boolean) => visible && (lecture.setTempVideoSrc())">
          <BaseSpinner />
        </div>
        <video v-else
               class="w-full h-full rounded-lg"
               controls>
          <source :src="lecture.tempVideoSrc"
                  type="video/mp4">
        </video>
      </template>
    </BaseRatio>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import marked from 'marked'
import { VideoSource } from '/-/plugins/helpers/videoHelper'
import { Lecture } from '/~/models/lecture'
import { plainToClass } from 'client-core/plugins/helpers'
import BaseSpinner from '/-/components/spinner/base-spinner.vue'
import { useEvents } from '/~/state/events'
import vObserveVisibility from '/-/directives/visibility'

interface FormDataInterface {
  title?: string
  description?: string
  textColor?: string
  source: VideoSource
  videoId?: string
  videoUrl?: string
}

interface PropsInterface {
  formData: FormDataInterface
  id: string
}

const props = defineProps<PropsInterface>()
const { event } = useEvents()

const lecture = computed(() => {
  const lecture = reactive(plainToClass({
    speakers: [], // for class transformers
    event: { id: event.value?.id },
    video_url: props.formData.videoUrl,
    video_id: props.formData.videoId,
    video_source: props.formData.source
  }, Lecture))

  return lecture
})
</script>
