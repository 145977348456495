import { v4 as uuid } from 'uuid'
import { Block } from '/~/models/page-editor'
import PageEditorImagesBlock from '/~/page-editor/images'
import PageEditorMarkdownBlock from '/~/page-editor/markdown'
import PageEditorVideoBlock from '/~/page-editor/video'
import PageEditorContentBlock from '/~/page-editor/content'
import PageEditorCardsBlock from '/~/page-editor/cards'
import PageEditorBannersBlock from '/~/page-editor/banners'
import { reactive, toRef, computed } from 'vue'
import { AcademyLesson, AcademyChatLesson } from '/~/models/academy'
import ProcessorElastic from '/-/plugins/processor/processor-elastic'
import { Api } from '/-/plugins/api'
import { plainToClass, classToPlain } from '/-/plugins/helpers'
import { useEvents } from '/~/state/events'
import { OptionInterface } from '/-/components/controls/setup'
import { autocompleteToOption, AutocompleteResponse } from '../models/autocomplete'

const { eventId } = useEvents()

interface StateInterface {
  lesson: AcademyLesson | null
  list: ProcessorElastic<AcademyLesson> | null,
  isProcessing: boolean
  isEditMode: boolean
  isLoaded: boolean
}

const state = reactive<StateInterface>({
  lesson: null,
  list: null,
  isProcessing: false,
  isEditMode: false,
  isLoaded: false,
})

// keep in mind that each type of block must have its own id (in index.ts)
const blocksList = [
  {
    ...PageEditorImagesBlock,
  },
  {
    ...PageEditorMarkdownBlock,
  },
  {
    ...PageEditorVideoBlock,
  },
  {
    ...PageEditorContentBlock,
  },
  {
    ...PageEditorCardsBlock,
  },
  {
    ...PageEditorBannersBlock,
  },
] as Block[]

const blocksMap = blocksList.reduce((map, item) => {
  map[item.id] = item
  return map
}, {} as { [key: number]: any })

const removeTempKeys = (data: any): any => {
  if (typeof data !== 'object' || data === null) {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((item) => removeTempKeys(item))
  }

  return Object.keys(data).reduce((acc, key) => {
    if (!key.startsWith('tmp_')) {
      acc[key] = removeTempKeys(data[key])
    }
    return acc
  }, {} as any)
}

const blocksToString = (blocks: Block[]) => {
  return JSON.stringify(
    blocks.map((block) => {
      return { id: block.id, uuid: block.uuid, editorData: removeTempKeys(block.editorData), hidden: !!block.hidden }
    })
  )
}

const stringToBlocks = (content: string | undefined) => {
  if (content) {
    const data = JSON.parse(content)

    return data.map((item: any) => ({
      ...blocksMap[item.id],
      ...{ editorData: item.editorData },
      hidden: !!item.hidden,
      uuid: item.uuid ?? uuid(),
      key: Symbol('key'),
    }))
  } else {
    return []
  }
}

const lessonBlocks = computed<Block[]>(() => {
  return stringToBlocks(state.lesson?.content)
})

async function initLessonsList(moduleId: number) {
  state.list = new ProcessorElastic({
    fetch: async (page: number) => {
      const data = await Api.fetch({
        url: `/${eventId.value}/academy/lessons`,
        params: {
          page,
          'filter[module_id]': moduleId,
        }
      })

      return data
    },
    mapping: item => plainToClass(item, AcademyLesson)
  })
}

async function createLesson(lesson: Partial<AcademyLesson>) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/academy/admin/lessons`,
    method: 'POST',
    body: classToPlain(lesson, AcademyLesson)
  })

  state.lesson = plainToClass(data, AcademyLesson)
}

async function fetchLesson(id: number) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/academy/lessons/${id}`,
  })

  state.lesson = plainToClass(data, AcademyLesson)
}

async function getLesson(id: number) {
  const { data } = await Api.fetch({
    url: `/academy/lessons/${id}`,
  })

  return plainToClass(data, AcademyChatLesson)
}

async function updateLesson(id: number, lesson: Partial<AcademyLesson>) {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/academy/admin/lessons/${id}`,
    method: 'PUT',
    body: classToPlain(lesson, AcademyLesson)
  })

  state.lesson = plainToClass(data, AcademyLesson)
}

async function deleteLesson(id: number) {
  await Api.fetch({
    url: `/${eventId.value}/academy/admin/lessons/${id}`,
    method: 'DELETE',
  })
}

function resetLesson() {
  state.lesson = null
}

async function getNextPriority(moduleId: number) {
  const data = await Api.fetch({
    url: `/${eventId.value}/academy/lessons`,
    params: {
      'filter[module_id]': moduleId,
    }
  })

  return data.meta.total + 1
}

async function updateSort(modules: Partial<AcademyLesson>[]) {
  await Api.fetch({
    url: `/${eventId.value}/academy/admin/lessons/sort`,
    method: 'PUT',
    body: { items: modules },
  })
}

async function getLessonsAutocomplete(query: string): Promise<OptionInterface[]> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/academy/lessons/autocomplete`,
    params: {
      'filter[query]': query,
    }
  }) as { data: AutocompleteResponse[] }

  return data.map(autocompleteToOption)
}

export function useAcademyLessons() {
  return {
    lesson: toRef(state, 'lesson'),
    list: toRef(state, 'list'),
    isEditMode: toRef(state, 'isEditMode'),
    isProcessing: toRef(state, 'isProcessing'),
    isLoaded: toRef(state, 'isLoaded'),
    initLessonsList,
    lessonBlocks,
    blocksList,
    blocksMap,
    stringToBlocks,
    getLessonsAutocomplete,
    updateSort,
    getNextPriority,
    createLesson,
    fetchLesson,
    getLesson,
    updateLesson,
    deleteLesson,
    resetLesson,
    blocksToString,
  }
}
