export const DEFAULT_COLOR_GRAY = '#414141'
export const DEFAULT_COLOR_GRAY_LIGHT = '#A3ADBB'
export const DEFAULT_COLOR_PRIMARY = '#0D65D8'
export const DEFAULT_COLOR_WHITE = '#ffffff'
export const DEFAULT_IMAGE_SQUARE =
  'https://cdn.wnhub.io/29192/64de06ee996fd5.07154662.png'
export const DEFAULT_IMAGE_HORIZONTAL =
  'https://cdn.wnhub.io/29193/64de07c7a8b753.02448512.png'
export const DEFAULT_LINK = 'https://wnhub.io/'
export const DEFAULT_MARKDOWN_PLACEHOLDER = 'Write your markdown text here'
export const DEFAULT_MARKDOWN_TEXT = 'Default **markdown** text'
export const DEFAULT_JOB = 'CEO and Creative Director'
export const DEFAULT_NAME = 'Name'
export const DEFAULT_TEXT = 'Default'
