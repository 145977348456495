import EditorUploadWidget from '/~/page-editor/widgets/EditorUploadWidget.vue'
import EditorMarkdownWidget from '/~/page-editor/widgets/EditorMarkdownWidget.vue'
import EditorColorWidget from '/~/page-editor/widgets/EditorColorWidget.vue'
import EditorVideoInputWidget from '/~/page-editor/widgets/EditorVideoInputWidget.vue'
import EditorShowAllSettingsWidget from '/~/page-editor/widgets/EditorShowAllSettingsWidget.vue'
import EditorBlockListWidget from '/~/page-editor/widgets/EditorBlockListWidget.vue'
import { App } from 'vue'

export default {
  install: (app: App<Element>) => {
    const components = {
      EditorUploadWidget,
      EditorMarkdownWidget,
      EditorColorWidget,
      EditorVideoInputWidget,
      EditorShowAllSettingsWidget,
      EditorBlockListWidget,
    }

    Object.entries(components).forEach(([name, component]) => {
      app.component(name, component)
    })
  }
}
