import { markRaw } from 'vue'
import { DEFAULT_IMAGE_SQUARE, DEFAULT_MARKDOWN_TEXT } from '../constants'
import View from './ContentView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  id: 5,
  viewComponent: markRaw(View),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    images: [DEFAULT_IMAGE_SQUARE],
    text: DEFAULT_MARKDOWN_TEXT,
    colsNumberOnDesktop: 1,
    colsNumberOnMobile: 1,
    isUseSliderOnMobile: false,
    isOpenFullscreen: false,
    rowsNumberInSliderOnMobile: 1,
    buttons: [],
  },
}
