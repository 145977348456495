import {
  DEFAULT_COLOR_GRAY,
  DEFAULT_COLOR_PRIMARY,
  DEFAULT_LINK,
  DEFAULT_TEXT,
} from './constants'

export interface ButtonInterface {
  type: 'button' | 'link'
  look: 'solid' | 'border'
  color: string
  textColor?: string
  url: string
  text: string
  isExternalLink?: boolean
  blockHash?: string
  isBlank?: boolean
}

export const isBlankSchema = {
  isBlank: {
    title: 'Open link in new window',
    description:
      'Only works for absolute links that start with "http..." and editor blocks (hash)',
    type: 'boolean',
    default: false,
  },
}

export const getButtonStyle = (button: ButtonInterface) => {
  if (button.type === 'button') {
    if (button.look === 'border') {
      return {
        border: `2px solid ${button.color}`,
        color: button.color,
      }
    } else {
      return {
        'background-color': button.color,
        color: button.textColor || 'white',
      }
    }
  } else {
    return {
      color: button.color,
    }
  }
}

const PaddingsList = ['none', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const

export type PaddingSize = (typeof PaddingsList)[number]

export const getPaddingClass = (size?: PaddingSize) => {
  switch (size) {
  case 'none':
    return ''
  case 'xxs':
    return 'py-2 md:py-4'
  case 'xs':
    return 'py-6 md:py-8'
  case 'sm':
    return 'py-14 md:py-20'
  case 'md':
    return 'py-20 md:py-40'
  case 'lg':
    return 'py-24 md:py-48'
  case 'xl':
    return 'py-32 md:py-56'
  default:
    return 'py-6 md:py-8'
  }
}

export const buttonsPropertiesSchema = {
  buttons: {
    type: 'array',
    title: 'A list of buttons',
    'ui:options': {
      showIndexNumber: true,
    },
    items: {
      type: 'object',
      required: ['buttonUrl', 'buttonText'],
      properties: {
        type: {
          title: 'Button style',
          enum: ['button', 'link'],
          enumNames: ['Button', 'Link'],
          default: 'button',
          'ui:widget': 'RadioWidget',
        },
        look: {
          title: 'Button look',
          enum: ['solid', 'border'],
          enumNames: ['Solid', 'Border'],
          default: 'solid',
          'ui:widget': 'RadioWidget',
          'ui:hidden': '{{parentFormData.type === "link"}}',
        },
        color: {
          title: 'Color',
          type: 'string',
          default: DEFAULT_COLOR_PRIMARY,
          'ui:widget': 'EditorColorWidget',
        },
        textColor: {
          title: 'Text color',
          type: 'string',
          default: '#ffffff',
          'ui:hidden':
            '{{parentFormData.type === "link" || parentFormData.look !== "solid"}}',
          'ui:widget': 'EditorColorWidget',
        },
        text: {
          title: 'Button text',
          type: 'string',
          default: DEFAULT_TEXT,
        },
        isExternalLink: {
          title: 'Is external link',
          type: 'boolean',
          default: true,
        },
        blockHash: {
          title: 'Editor block',
          type: 'string',
          description:
            'To make it easier to find the block here, first fill in the field "In-page block id"',
          'ui:hidden': '{{ parentFormData.isExternalLink === true }}',
          'ui:widget': 'EditorBlockListWidget',
        },
        url: {
          title: 'External link',
          type: 'string',
          default: DEFAULT_LINK,
          'ui:hidden': '{{ parentFormData.isExternalLink === false }}',
        },
        ...isBlankSchema,
      },
    },
  },
}

export const textAlignSchemaField = {
  title: 'Text align',
  type: 'string',
  enum: ['start', 'center', 'end'],
  enumNames: ['Left', 'Center', 'Right'],
  default: 'start',
}

export const titlePropertiesSchema = {
  title: {
    title: 'Title',
    type: 'string',
    default: undefined,
  },
  titleAlign: {
    ...textAlignSchemaField,
    title: 'Title align',
    'ui:widget': 'RadioWidget',
  },
}

export const titleSizeMap = {
  xl: 'text-xl',
  xl2: 'text-2xl',
  xl3: 'text-2xl md:text-3xl',
} as { [key: string]: string }

export const titleSizeSchema = {
  titleSize: {
    title: 'Title size',
    type: 'string',
    enum: ['xl', 'xl2', 'xl3'],
    enumNames: ['XL', '2XL', '3XL'],
    default: 'xl3',
  },
}

export const paddingsSchema = {
  paddingSize: {
    title: 'Block vertical paddings',
    enum: PaddingsList,
    enumNames: PaddingsList,
    default: 'xs',
    'ui:widget': 'RadioWidget',
  },
}

export const bgColorSchema = {
  bgColor: {
    title: 'Block background color',
    type: 'string',
    default: 'transparent',
    'ui:widget': 'EditorColorWidget',
  },
}

export const linkColorSchema = {
  linkColor: {
    title: 'Links color',
    type: 'string',
    default: DEFAULT_COLOR_PRIMARY,
    'ui:widget': 'EditorColorWidget',
  },
}

export const textColorSchema = {
  textColor: {
    title: 'Text color',
    type: 'string',
    default: DEFAULT_COLOR_GRAY,
    'ui:widget': 'EditorColorWidget',
  },
}

export const showAllSettingsSchema = {
  tmp_showAllSettings: {
    title: 'Show all settings',
    type: 'string',
    'ui:widget': 'EditorShowAllSettingsWidget',
  },
}
